import React from 'react';
import { MapPopup as PopupInst } from '@components/MapPopup/MapPopup';
import ReactDOM from 'react-dom';
import matomoConfig from '../matomoConfig';
import { MatomoProvider } from 'matomo-react';

// Add Popups
export function createPopup(feature, layer, history) {
  const placeholder = document.createElement('div');
  const mediaQuery = window.matchMedia('(min-width: 426px)');

  let popupOptions = {};
  if (mediaQuery.matches) {
    popupOptions = {
      autoPanPaddingTopLeft: [100, 100],
      keepInView: true,
      autoPan: true,
    };
  } else {
    popupOptions = {
      autoPan: true,
      keepInView: true,
      autoClose: false,
    };
  }

  // bind to click event to make the PopupInst component only call for Data when opened,
  // not for every instance, which fails due to restrictions serverside
  layer.on('click', function (e) {
    ReactDOM.render(
      <MatomoProvider value={matomoConfig}>
        <PopupInst
          data={feature}
          layer={layer}
          bindPopup={() => {
            layer.bindPopup(placeholder, popupOptions);
            this.openPopup();
          }}
          history={history}
        />
      </MatomoProvider>,
      placeholder
    );
    layer.bindPopup(placeholder, popupOptions);
    this.openPopup();
  });

  layer.on('popupopen', function (e) {
    layer.bindPopup(placeholder, popupOptions);
  });

  layer.on('popupclose', () => {
    layer.unbindPopup(placeholder);
  });
}
