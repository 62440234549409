import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import { MapContext } from '../../context/MapContext';
import {
  debounce,
  get_map_bounds,
  goTo_geolocation,
} from '../../lib/helperFunctions';
import { useLocation, useHistory } from 'react-router-dom';

function MapPlaceholder() {
  return (
    <p>
      <noscript>You need to enable JavaScript to see this map.</noscript>
    </p>
  );
}

const MAX_ZOOM = 18;
const DEFAULT_ZOOM = 7;

export const Map = () => {
  const { setMap: setMapFromContext, map: mapContextState } =
    useContext(MapContext);

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const zoomParam = queryParams.get('zoom');

  const zoomLevel =
    zoomParam && parseInt(zoomParam, 10) <= MAX_ZOOM ? zoomParam : DEFAULT_ZOOM;
  const centerParam = queryParams.get('center');

  const mapCenter = centerParam
    ? centerParam.split(',')
    : [51.318008, 9.468067];

  const [mapProperties, setMapProperties] = useState({
    center: mapCenter,
    zoom: zoomLevel,
    layers: [],
    bounds: null,
  });

  const { center, zoom, bounds } = mapProperties;

  const onMove = useCallback(() => {
    let bounds = get_map_bounds(mapContextState);
    setMapProperties((prev) => ({
      ...prev,
      bounds: bounds.Bounds,
      zoom: bounds.zoom,
      center: [
        mapContextState.getCenter().lat,
        mapContextState.getCenter().lng,
      ],
    }));
  }, [mapContextState]);

  const flyToGivenKoords = useCallback(() => {
    let queryParams = new URLSearchParams(window.location.search);

    const [lat, lng] = queryParams.get('center')
      ? queryParams.get('center').split(',')
      : [null, null];

    const zoom = queryParams.get('zoom');

    if (lat !== null && lng !== null) {
      // Koords given in URL ?
      goTo_geolocation(mapContextState, { lat, lng }, zoom); // go there
      return true;
    }

    return false;
  }, [mapContextState]);

  const setCurrentParams = useCallback(() => {
    const currentQueryParams = new URLSearchParams(window.location.search);
    currentQueryParams.set(
      'center',
      `${mapContextState.getCenter().lat},${mapContextState.getCenter().lng}`
    );

    currentQueryParams.set('zoom', mapContextState.getZoom());

    // debounce history.push to let actions like zooming, popup open finish
    const debouncedHistoryPush = debounce((search) => {
      history.push({ search });
    }, 500);

    debouncedHistoryPush(currentQueryParams.toString());
  }, [mapContextState, history]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    if (mapContextState) {
      //on initial load,  when map is ready and koords are not given in URL
      // fly to device position
      if (!navigator.geolocation) {
        console.warn('Geolocation is not supported by your browser');
      } else if (!queryParams.get('center') && navigator.geolocation) {
        // Get the current location
        navigator.geolocation.getCurrentPosition(
          (position) => {
            mapContextState.flyTo(
              { lat: position.coords.latitude, lng: position.coords.longitude },
              mapContextState.getZoom()
            );

            sessionStorage.setItem(
              'location',
              JSON.stringify({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
            );
          },
          (err) => {
            console.warn(`Error getting location: ${err.message}`);
          }
        );
      }

      onMove();
      mapContextState.on('moveend', () => {
        onMove();
        setCurrentParams();
      });
    }
  }, [onMove, mapContextState, flyToGivenKoords, setCurrentParams]);

  const displayMap = useMemo(
    () => (
      <MapContainer
        center={center}
        zoom={zoom}
        placeholder={<MapPlaceholder />}
        whenCreated={setMapFromContext}
        style={{ height: '100vh' }}
        bounds={bounds}
        maxZoom={19}
        boundsOptions={{ maxZoom: 19 }}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <ZoomControl position='bottomright' />
      </MapContainer>
    ),
    [bounds, center, zoom, setMapFromContext]
  );

  return <div data-testid='map-leaftlet-container'>{displayMap}</div>;
};
