import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = '360px';

const useStyles = makeStyles(() => ({
  menuDrawer: {
    width: drawerWidth,
    flexShrink: 0,
    background: '#fff',
    position: 'absolute',
    // zIndex: '1200',
    left: '0',
    height: '100%',
    fontFamily: "'TradeGothic', sans-serif, sans-serif",
    '& > div': {
      width: drawerWidth,
    },
  },

  navLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

export default useStyles;
