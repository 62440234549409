import { InfoOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';

const MUIToolTip = React.lazy(() => import('@mui/material/Tooltip'));

const useStyles = makeStyles({
  icon: {
    color: '#7C7777',
  },

  button: {
    maxWidth: 'fit-content',
    minWidth: 'fit-content',
    padding: '4px',
    '& .MuiButton-label': { width: 'fit-content' },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const CustomTooltip = withStyles(() => ({
  tooltipPlacementTop: {
    margin: '2px 0 !important',
  },
  arrow: {
    '&:before': {
      backgroundColor: '#fff !important',
      border: '1px solid #dadde9 !important',
    },
  },
  tooltip: {
    backgroundColor: '#fff !important',
    color: '#333 !important',
    maxWidth: 300,
    border: '1px solid #dadde9 !important',
    fontSize: '0.8rem',
  },
}))(MUIToolTip);

const Tooltip = ({ children, name, color = 'gray' }) => {
  const { icon, button } = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState({});

  return (
    <CustomTooltip
      open={tooltipOpen[name] ? tooltipOpen[name] : false} // tooltipOpen is a controlled component
      onClose={() => setTooltipOpen({ [name]: false })}
      enterTouchDelay={0}
      enterDelay={0}
      onOpen={(e) => {
        e.stopPropagation();
        setTooltipOpen({ [name]: true });
      }}
      leaveTouchDelay={5000}
      title={<React.Fragment>{children}</React.Fragment>}
      placement='top'
      arrow
    >
      <Button className={button} disableRipple>
        <InfoOutlined
          fontSize='small'
          sx={{
            path: { fill: color },
          }}
          className={icon}
        />
      </Button>
    </CustomTooltip>
  );
};

export default Tooltip;
