import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Markdown from 'markdown-to-jsx';
import { CloseSharp } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
import useStyles from './menuDialogStyles';
import { MutAtlasLogo } from '../../icons/logos';
import { Colours } from '../../theme';

export const MenuDialogContent = () => {
  let history = useHistory();
  let { markdownFileName } = useParams();

  const [dialog, setDialog] = useState(true);
  const { menuDialog, menuDialogHeader } = useStyles();

  const [content, setContent] = React.useState('');
  const [markdownState, setMarkdownState] = React.useState({
    isLoading: true,
    error: null,
  });

  const getMarkdown = useCallback(async () => {
    setMarkdownState({
      isLoading: true,
    });
    if (!markdownFileName) return;
    try {
      // use (-) for link then replace just because underscore (_) links are ugly
      const res = await import(
        '../staticPages/' + markdownFileName.replaceAll('-', '_') + '.md'
      );
      const response = await fetch(res.default);
      const text = await response.text();
      setContent(text);
      setMarkdownState({
        isLoading: false,
      });
    } catch (error) {
      setMarkdownState({
        isLoading: false,
        error: `Content not found: ${error.message}`,
      });
    }
  }, [markdownFileName]);

  useEffect(() => {
    getMarkdown();
  }, [getMarkdown]);

  const cachedMarkdown = useMemo(() => content, [content]);

  return (
    <Dialog
      open={dialog}
      onClose={() => {
        history.push('/home');
        setDialog(false);
      }}
      maxWidth={'md'}
      scroll={'paper'}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title' className={menuDialogHeader}>
        <Box
          display={'flex'}
          alignContent={'center'}
          justifyContent={'flex-end'}
        >
          <IconButton
            onClick={() => {
              history.push('/home');
              setDialog(false);
            }}
            size='large'
          >
            <CloseSharp
              style={{
                color: '#fff',
              }}
            />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={menuDialog}>
        {cachedMarkdown ? (
          <Markdown
            options={{
              forceBlock: true,
              overrides: {
                a: {
                  props: {
                    target: '_blank',
                  },
                },
              },
            }}
          >{cachedMarkdown}</Markdown>
        ) : (
          <Box
            p={16}
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
            minWidth={'100%'}
          >
            <MutAtlasLogo fill={Colours._darkGreen} />
            {markdownState.isLoading ? (
              <Typography variant={'h6'}>Wird geladen...</Typography>
            ) : (
              <>
                <Typography variant={'h6'}>
                  Leider konnten wir die von Ihnen gesuchte Seite nicht finden.
                </Typography>
                <Button onClick={() => getMarkdown()}>Erneut versuchen</Button>
              </>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
