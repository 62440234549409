import DialogTitle from '@mui/material/DialogTitle';
import React, { Suspense } from 'react';
// import Dialog from '@mui/material/Dialog';
import { CloseSharp } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
const Dialog = React.lazy(() => import('@mui/material/Dialog'));

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 4,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    padding: '8px 20px',
  },
  openHelp: {
    color: 'var(--dark-green)',
    fontWeight: 600,
    cursor: 'pointer',
  },

  buttonContainer: {
    gap: '1rem',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '.8rem',
  },

  primaryButton: {
    background: 'var(--dark-green)',
    color: '#fff',
    padding: '0.5em 2em',
    textTransform: 'none',
    '&:hover': {
      background: 'var(--dark-green)',
    },
  },
  secondaryButton: {
    background: 'transparent',
    fontWeight: 600,
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
    },
  },

  hideLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiFormControlLabel-label': {
      paddingTop: '0.25rem',
      fontSize: '.8rem',
    },
    '& .MuiSvgIcon-root': {
      width: '.9rem',
    },
    '& .MuiButtonBase-root': {
      padding: '2px',
    },
  },
});

export const Hinweis = (props) => {
  const classes = useStyles();
  const { popupStatus, setpopupStatus } = props;
  const history = useHistory();

  const handleClose = () => {
    setpopupStatus(false);
  };

  const handleSelect = (event) => {
    window.localStorage.setItem('hideHinweis', event.target.checked);
  };

  const { root, hideLabel, buttonContainer, primaryButton, secondaryButton } =
    classes;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Dialog
        onClose={handleClose}
        open={popupStatus}
        onKeyPress={handleClose}
        scroll={'paper'}
        sx={{
          '.MuiPaper-root': {
            overflowX: 'hidden',
            overflowY: 'auto',
          },
        }}
      >
        <DialogTitle className={root}>
          <Box
            display={'flex'}
            alignContent={'center'}
            justifyContent={'flex-end'}
          >
            <IconButton onClick={handleClose} size='large'>
              <CloseSharp
                style={{
                  color: '#fff',
                }}
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <Card>
          <CardContent
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <Typography
              variant='h1'
              textAlign={'center'}
              fontWeight={400}
              sx={{
                fontSize: {
                  lg: 26,
                  sm: 20,
                  xs: 18,
                },
              }}
            >
              Akute Krise oder allgemeine Recherche?
            </Typography>
            <Typography
              mt={1.5}
              lineHeight={1.5}
              textAlign={'center'}
              sx={{
                fontSize: {
                  lg: 16,
                  sm: 16,
                  xs: 14,
                },
              }}
            >
              Wenn du gerade in einer akuten Krise bist oder Suizidgedanken hast
              – oder dies bei einer dir nahestehenden Person beobachtest, dann
              findest du hier mit einem Klick Hilfsangebote. Wenn du sonstige
              Hilfen im MUT-ATLAS finden möchtest, kannst du mit der allgemeinen
              Suche fortfahren.
            </Typography>
            <Box display={'flex'} className={buttonContainer} mt={'1.5rem'}>
              <Button
                className={primaryButton}
                onClick={() => {
                  history.push('/home/akute_krise');
                  setpopupStatus(false);
                }}
              >
                Notfallkontakte anzeigen
              </Button>
              <Button
                onClick={handleClose}
                tabIndex={0}
                className={secondaryButton}
              >
                Zur allgemeinen Suche
              </Button>
            </Box>

            <FormControlLabel
              className={hideLabel}
              label={'Nicht mehr anzeigen'}
              control={
                <Checkbox
                  color='secondary'
                  onChange={handleSelect}
                  name={'hide-hinweis'}
                />
              }
            />
          </CardContent>
        </Card>
      </Dialog>
    </Suspense>
  );
};
