import React, { useEffect, useReducer } from 'react';
import clsx from 'clsx';

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import useStyles from './Ui-styles';
import { Box, Button, useMediaQuery } from '@mui/material';
import { MenuButton } from './MenuDrawer/MenuButton';
import { Menu } from './MenuDrawer';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import { MenuDialogContent } from './MenuDialog';
import { MutAtlasLogo, MutLogo } from '../icons/logos';
import { styled } from '@mui/styles';
import FilterComponent from './Filter/FilterComponent';
import { MapContext } from '../context/MapContext';
import Search from './Search';
import { AngebotContext } from '../context/AngebotContext';

const linkStyles = {
  textDecoration: 'none',
  color: 'white',
  '&:hover': {
    textDecoration: 'none',
  },
};
const StyledLink = styled(Link)(linkStyles);

export const MEDIA = '(max-width: 959px)';

export default function UI() {
  let match = useRouteMatch();
  const classes = useStyles();

  const [menuState, setMenuState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      menuOpen: false,
      menuDialogOpen: false,
      markdownUrl: '',
    }
  );

  const isMobile = useMediaQuery(MEDIA);
  const [open, setOpen] = React.useState();
  const { map } = React.useContext(MapContext);
  const { mapData } = React.useContext(AngebotContext);
  const { selectedFilterSearchData } = mapData || {};

  useEffect(() => {
    /* close sidebar on small devices */
    setOpen(!isMobile);
  }, [isMobile]);

  function handleClose() {
    const mobile = window.matchMedia(MEDIA).matches;
    mobile && setOpen(false);
  }

  return (
    <div id='overlay' className={classes.root}>
      {/* id="overlay" needed to stop mouse propagation  in App.js */}
      <CssBaseline />
      {isMobile && (
        <AppBar position='fixed' className={clsx(classes.appBar, open)}>
          <Toolbar className={clsx(classes.toolbar)}>
            <Box display='flex' alignItems='center' flexGrow={1}>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={() => setOpen(true)}
                edge='start'
                size='large'
              >
                <MenuIcon />
              </IconButton>
              <Box flexGrow={1} maxWidth={360}>
                <Search
                  angebotFilter={selectedFilterSearchData}
                  handleDrawerClose={() => false}
                />
              </Box>
            </Box>
            <MutAtlasLogo fill={'#fff'} />
          </Toolbar>
        </AppBar>
      )}

      {/* Static pages route */}
      <Route path={`${match.path}/:markdownFileName`}>
        <MenuDialogContent markdownUrl={'markdownUrl'} />
      </Route>
      <Menu menuState={menuState} setMenuState={setMenuState} />
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={open}
        classes={{ paper: classes.drawerPaper }}
        id='ui'
        style={{ display: isMobile && !open ? 'none' : 'block' }}
      >
        {isMobile && (
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Button
              onClick={() => setOpen(false)}
              className={classes.drawerHeader}
              size='small'
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <ChevronLeftIcon />
                <Typography variant='body1' color={'white'} mt={0.5}>
                  Schließen
                </Typography>
              </Box>
            </Button>

            <MenuButton setMenuOpen={() => setMenuState({ menuOpen: true })} />
          </Box>
        )}

        {!isMobile && (
          <Box pb={1}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              my={1}
            >
              <MenuButton
                setMenuOpen={() => setMenuState({ menuOpen: true })}
              />

              <a
                href='/'
                rel='noopener noreferrer'
              >
                <MutAtlasLogo fill='white' />
              </a>
            </Box>

          </Box>
        )}

        <FilterComponent
          map={map}
          handleDrawerClose={
            handleClose // close sidebar on small devices affter clicking search item
          }
          isMobile={isMobile}
        />

        <Box my={2} display={'flex'} gap='1rem'>
          <StyledLink to='/home/datenschutz'>
            <Typography color='white'>Datenschutz</Typography>
          </StyledLink>
          <StyledLink to='/home/impressum'>
            <Typography color='white'>Impressum</Typography>
          </StyledLink>
          <a
            style={linkStyles}
            rel={'noopener noreferrer external'}
            target='_blank'
            href='https://mut-foerdern.de/transparenz/'
          >
            <Typography color='white'>Transparenz</Typography>
          </a>
        </Box>
        <Box>
          <a
            href='https://www.mut-foerdern.de'
            target='_blank'
            rel='noopener noreferrer'
          >
            <MutLogo fill='white' />
          </a>

        </Box>
      </Drawer>
    </div>
  );
}
