export const sourceLinks = (source) => {
  let source_url = '';
  let source_name = '';

  if (source) {
    /* define Name + url for the Quelle */
    if (source.toLowerCase() === 'osm') {
      source_url = 'https://www.openstreetmap.org/copyright';
      source_name = 'OpenStreetMap';
    } else if (source.toLowerCase() === 'awo-sh') {
      source_url = 'https://awo-sh.de';
      source_name = 'Arbeiterwohlfahrt Landesverband Schleswig-Holstein e.V.';
    } else if (source.toLowerCase() === 'siegen-wittgenstein') {
      source_url = 'http://www.osw-gegen-depression.de';
      source_name = 'Bündnis gegen Depression, Olpe-Siegen-Wittgenstein';
    } else if (source.toLowerCase() === 'bvbud') {
      source_url = 'https://bvbud.de';
      source_name = 'Bundesverband Burnout und Depression e.V.';
    } else if (source.toLowerCase() === 'kjpsych-d') {
      source_url = 'https://www.gesundheitsamt-dadi.de';
      source_name = 'Gesundheitsamt Darmstadt-Dieburg';
    } else if (source.toLowerCase() === 'condrobs') {
      source_url = 'https://www.condrobs.de';
      source_name = 'Condrobs e.V.';
    } else if (source.toLowerCase() === 'vsp') {
      source_url = 'https://vsp-net.de/';
      source_name = 'VSP - Verein für Sozialpsychiatrie e.V.';
    } else if (source.toLowerCase() === 'psychnavihb') {
      source_url = 'https://www.psychnavi-bremen.de';
      source_name = 'psychNAVi Bremen';
    }
  }

  return { name: source_name, url: source_url };
};
