import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export default function SidebarCard({ children, classes }) {
  return (
    <Card className={` ${classes}`} variant='outlined'>
      <CardContent
        sx={{
          paddingTop: 1,
          minHeight: '45px',
          minWidth: '100%',
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
}
