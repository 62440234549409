import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { App } from './App';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import theme from './theme';
import { Info } from './pages/info/Info';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <StrictMode>
    <BrowserRouter basename={baseUrl}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/home' />
            </Route>
            <Route path='/home'>
              <App />
            </Route>
            <Route path='/info' component={Info} />
          </Switch>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </StrictMode>,
  rootElement
);
