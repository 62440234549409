import { makeStyles } from '@mui/styles';
import React, { Fragment, Suspense } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
} from '@mui/material';
import { TreeItem, TreeView, useTreeItem } from '@mui/x-tree-view';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import {
  CheckedIcon,
  InterdeterminateIcon,
  UncheckedIcon,
} from '../../lib/filterIcons';
import { Colours } from '../../theme';

// lazy load component
const Tooltip = React.lazy(() => import('./Tooltip'));

const useStyles = makeStyles({
  optLabel: {
    '& > span': {
      fontSize: '.8rem',
    },
    '& .MuiSvgIcon-root': {
      width: '1rem',
    },
    '& MuiCollapse-wrapperInner': {
      flexDirection: 'column',
      display: 'flex',
    },
  },
});

export default function FilterTree({
  data,
  changeHandler,
  checked,
  filtersArrayFromUrl,
}) {
  const { optLabel } = useStyles();

  // für Nodes ohne Kinder
  const handleSelect = (ev, name) => {
    /* der treeview schickt einen name, die checkbox ein event,
     * das muss ich hier auseinander dröseln
     */
    switch (typeof ev.target.name) {
      case 'undefined':
        changeHandler(name);
        break;
      default:
        changeHandler(ev.target.name);
    }
  };

  return (
    <TreeView expanded={filtersArrayFromUrl} onNodeSelect={handleSelect}>
      {data.map(({ name, infotext, icon, details }) => {
        const parentInfoText = infotext?.find(
          (el) => el.wert === name
        ).infotext;

        const hasSubitems = details.length > 0;
        const hasAllSubItemsChecked =
          hasSubitems && details.every((subItem) => checked[subItem.wert]);
        const hasSomeSubItemsChecked =
          hasSubitems && details.some((subItem) => checked[subItem.wert]);

        const checkIcon =
          hasAllSubItemsChecked || (!hasSubitems && checked[name]) ? (
            <CheckedIcon />
          ) : hasSomeSubItemsChecked || checked[name] ? (
            <InterdeterminateIcon />
          ) : (
            <UncheckedIcon />
          );

        return (
          <CustomTreeItem
            key={name}
            nodeId={name}
            icon={checkIcon}
            parentInfoText={parentInfoText}
            name={name}
            details={details}
            checked={checked}
            label={
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                gap={1}
              >
                {icon}
                <Box
                  component={'span'}
                  flex={'auto'}
                  width={172}
                  sx={{
                    wordBreak: 'break-word',
                    color: checked[name] ? Colours._darkGreen : 'inherit',
                  }}
                >
                  {name}
                </Box>
              </Box>
            }
            className={String(
              Object.entries(checked).map((el) => {
                return el[1] === true && name === el[0] ? `selected` : null;
              })
            )}
            sx={{
              '.MuiTreeItem-content': {
                paddingLeft: '4px',
                '.Mui-selected': {
                  backgroundColor: Colours._lightGrey,
                  'MuiTypography-root ': {
                    color: Colours._darkGreen,
                  },
                },
              },
            }}
          >
            {details.map(({ wert, infotext, anmerkung }, i) => {
              return (
                <Fragment key={`${wert}-${i}`}>
                  {/* details are sorted by anmerkung
                    use anmerkung to add border to the top or bottom of the box to separate the therapies
                    maybe there is a better way to do this from what the api returns. Margin left takes in to account the initial margin from the filter */}
                  {anmerkung.includes('_00205_') && (
                    <Divider
                      sx={{
                        marginLeft: '-12px',
                        marginTop: '0.25rem',
                        marginBottom: '0.25rem',
                      }}
                    />
                  )}
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <FormControlLabel
                      className={optLabel}
                      label={wert}
                      control={
                        <Checkbox
                          sx={{
                            color: 'var(--dark-green)',
                            padding: 0.5,
                            marginBottom: 0.5,
                            '&.Mui-checked': {
                              color: 'var(--dark-green)',
                            },
                          }}
                          checked={checked[wert] ? true : false}
                          onChange={(e) => handleSelect(e, wert)}
                          name={wert}
                        />
                      }
                    />

                    <Suspense fallback={<div>..</div>}>
                      {infotext ? (
                        <Tooltip name={wert}>{infotext}</Tooltip>
                      ) : null}
                    </Suspense>
                  </Box>
                </Fragment>
              );
            })}
          </CustomTreeItem>
        );
      })}
    </TreeView>
  );
}

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    parentInfoText,
    name,
    details,
    checked,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    <Box
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      display='flex'
      alignContent='center'
      onMouseDown={handleMouseDown}
      gap={1}
      marginTop={0.5}
      ref={ref}
    >
      <div onClick={handleSelectionClick} className={classes.iconContainer}>
        {icon}
      </div>

      <span onClick={handleSelectionClick}>{label}</span>

      <Suspense fallback={<div></div>}>
        {parentInfoText ? (
          <Tooltip name={name}>{parentInfoText}</Tooltip>
        ) : null}
      </Suspense>

      <Button
        sx={{
          minWidth: 'fit-content',
          padding: '0',
          marginLeft: 'auto',
        }}
        onClick={handleSelectionClick}
      >
        {details.length > 0 ? (
          <>
            {checked[name] ? (
              <ExpandLessIcon
                sx={{
                  color: Colours._darkGreen,
                }}
              />
            ) : (
              <ExpandMoreIcon
                sx={{
                  color: Colours._textGrey,
                }}
              />
            )}
          </> // wenn es keine details gibt, dann kein icon
        ) : (
          <ExpandMoreIcon
            sx={{
              color: 'transparent', // damit der abstand stimmt
            }}
          />
        )}
      </Button>
    </Box>
  );
});

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  return (
    <TreeItem
      ContentComponent={CustomContent}
      ContentProps={props}
      {...props}
      ref={ref}
      sx={{
        '.MuiTreeItem-iconContainer': {
          display: 'flex',
          alignItems: 'center',
        },
      }}
    />
  );
});
