import { Box, IconButton, Modal } from '@mui/material';
import React from 'react';
import { MapPopup } from './MapPopup';
import { Close } from '@mui/icons-material';
import { MatomoProvider } from 'matomo-react';
import matomoConfig from '../../matomoConfig';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 2,
  maxHeight: '85vh',
  overflowY: 'auto',
};

const iconStyle = {
  position: 'absolute',
  top: 12,
  right: 12,
  color: 'white',
  background: '#bcc2bc',
  padding: 0.5,
  borderRadius: '50%',
};

export const SearchModal = ({ searchedAngebot, handleClose }) => {
  const { guid, show } = searchedAngebot;
  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <IconButton onClick={() => handleClose()} sx={iconStyle}>
          <Close fontSize='small' />
        </IconButton>
        <MatomoProvider value={matomoConfig}>
          <MapPopup
            data={{
              properties: { searchResult: true, guid },
            }}
          />
        </MatomoProvider>
      </Box>
    </Modal>
  );
};
