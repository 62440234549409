import React from 'react';

export const PopupError = ({ status }) => {
  return (
    <div className='popup-wrap' id='partner_popup'>
      {status === 429 ? (
        <div style={{ textAlign: 'center' }}>
          Sorry.... Zugriffsbegrenzung...
          <p style={{ fontSize: '40px' }}>
            <span role='img' aria-label='Kuchen'>
              &#127847;
            </span>
          </p>{' '}
          <br /> Versuchen Sie es in wenigen Sekunden noch einmal.
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};
