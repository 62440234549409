import React from 'react';
import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const ScrollTopButton = () => {
  const classes = useStyles();

  return (
    <a href={'/info#info'} className={classes.root}>
      <Fab color='primary' size='medium' aria-label='scroll back to top'>
        <KeyboardArrowUpIcon />
      </Fab>
    </a>
  );
};

export default ScrollTopButton;
