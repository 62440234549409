import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  menuDialog: {
    zIndex: '100000',
    padding: '8px 20px',
    '& a': {
      color: 'rgb(64,155,162)',
      fontWeight: 'bold',
    },

    '& > *': {
      fontFamily: "'TradeGothic', sans-serif, sans-serif",
    },
  },
  menuDialogHeader: {
    background: 'rgb(64,155,162)',
    padding: '1.5rem',
  },
}));

export default useStyles;
