import { createTheme } from '@mui/material';

export const Colours = {
  _textGrey: '#7C7777',
  _lightGrey: '#F7F7F7',
  _darkGreen: '#409ba2',
  _deepGreen: '#00AAAD',
  _lightGreen: '#CCF0EF',
  hellGreen: '#D8DC40',
  _orange: '#FF6920',
  _lightOrange: '#F6DFD1',
  _lightYellow: '#FFFFCC',
  _darkYellow: '#B3B300',
};

const theme = createTheme({
  components: {
    MuiTreeItem: {
      styleOverrides: {
        content: {
          padding: '0',
        },
      },
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        margin: 8,
      },
    },
  },
  a: {
    textDecoration: 'none',
  },
  typography: {
    allVariants: {
      textTransform: 'none',
    },
    h4: {
      fontSize: '1.2rem',
    },
    h5: {
      fontSize: '1rem',
    },
    caption: {},
    subtitle1: {},
    subtitle2: {},
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.8rem',
    },
    body3: {
      fontSize: '0.75rem',
    },
  },
});

export default theme;
