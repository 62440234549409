import { Colours } from '../../theme';

const styles = {
  h5: {
    color: 'white',
    fontSize: 'large',
  },
  h6: {
    color: 'var(--dark-green)',
    fontSize: '1rem',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    gap: '.2rem',
    marginRight: '0',
    marginLeft: '0',
  },
  flexRow: {
    flexWrap: 'wrap',
  },
  optLabel: {
    '& span': {
      padding: '0em',
      fontSize: '0.8rem',
      display: 'inline',
    },
    '& span.MuiTypography-root': {
      marginBottom: '3px',
    },
    '& .MuiSvgIcon-root': {
      width: '1rem',
      height: '1rem',
    },
  },
  treeItem: {
    '& ul': { marginLeft: '0' },
  },
  optsBlock: {
    padding: '8px 0px 0px 0px',
  },
  accordion: {
    '& > * .MuiAccordionSummary-content': {
      margin: '0',
    },

    '& > * .MuiAccordionSummary-content.Mui-expanded': {
      marginBottom: '8px',
    },
    '& .MuiButtonBase-root.MuiAccordionSummary-root': {
      minHeight: 'unset',
    },
  },
  topBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflowY: 'hidden',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottom: 'none',
    boxShadow: 'none',
    minHeight: '45px',
    '& .MuiCardContent-root:last-child': {
      padding: '5px 16px',
      paddingBottom: '5px',
    },
  },
  optGrp: {
    borderTop: `1px solid ${Colours._lightGrey}`,
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  optHeading: {
    flexShrink: 0,
    color: 'var(--dark-green)',

    '& strong': {
      color: Colours._textGrey,
    },
  },
  btmBox: {
    overflowY: 'auto',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    border: 'none',
    paddingBottom: '8px',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 'inherit',
    },
  },
  mobBtn: {
    fontWeight: '700',
    color: 'var(--dark-green)',
    fontFamily: 'TradeGothic',
    marginTop: '1rem',
    borderColor: 'var(--dark-green)',
    border: '1px solid',
  },
};

export { styles };
