import { createInstance } from 'matomo-react';

const matomoConfig = createInstance({
  urlBase: 'https://www.mut-tour.de/matomo/',
  siteId: 7,

  configurations: {
    disableCookies: true,
    setDoNotTrack: true,
    setRequestMethod: 'POST',
    trackPageView: true,
    enableLinkTracking: true,
  },
});

export default matomoConfig;
