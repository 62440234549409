import {
  Box,
  TextField,
  TextareaAutosize,
  Typography,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import axios from 'axios';
import React, { useState } from 'react';
import { useStyles } from './PopupStyles';
import { CustomButton } from '../common/PopupButton';
import { useMatomo } from 'matomo-react';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'var(--dark-green)',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'var(--dark-green)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: 'var(--dark-green)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--dark-green)',
      },
    },
  },
})(TextField);

export const ErrorReporting = ({ listingAutoLfNr, guid, closeForm }) => {
  const { trackEvent } = useMatomo();

  const { span } = useStyles();

  const [status, setStatus] = useState({
    status: '',
    loading: false,
    error: false,
  });

  const [meldenFormValue, setMeldenFormValue] = React.useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setMeldenFormValue((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    // Matomo event
    // see https://github.com/maxfahl/matomo-react
    trackEvent({
      category: 'errorReporting',
      action: 'submitForm',
      value: listingAutoLfNr,
    });

    e.preventDefault();
    setStatus({ message: '', loading: true, error: false });

    const { from, sub, body } = meldenFormValue;
    let details = {
      to: 'hinweise@mut-foerdern.de',
      sub: `Problem with listing ${listingAutoLfNr} - ${sub}`,
      body: `${body}. 
      Listing Details: AutoLfNr: ${listingAutoLfNr}, 
      IB-url: https://intern.mut-foerdern.de/mut-atlas/einrichtung/${guid}
      Absender: ${from ? `${from}` : 'Anonymous'}`,
    };

    let res = await axios.post('https://mailer.mut-foerdern.de/send', details);

    if (res.status === 200 || res.status === 201) {
      setStatus({ message: 'Meldung gesendet', loading: false, error: false });
      setMeldenFormValue({});
      setTimeout(() => {
        closeForm();
      }, 4000);
      return;
    }

    setStatus({
      message: 'Nachricht konnte nicht gesendet werden',
      loading: false,
      error: true,
    });
  };
  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'}>
        <h2>Fehler melden</h2>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            closeForm();
          }}
          className={span}
        >
          <Box component={'span'} className={span}>
            Abbrechen
          </Box>
        </Button>
      </Box>

      <form onSubmit={handleSubmit} className='form-error'>
        <div>
          <CssTextField
            type='email'
            name='from'
            label='Deine E-Mailadresse (optional)'
            variant='outlined'
            value={meldenFormValue['from']}
            onChange={handleChange}
            fullWidth
          />
        </div>
        <div>
          <CssTextField
            name='sub'
            label='Thema'
            variant='outlined'
            fullWidth
            value={meldenFormValue['sub']}
            onChange={handleChange}
          />
        </div>
        <div>
          <TextareaAutosize
            name='body'
            aria-label='empty textarea'
            value={meldenFormValue['body']}
            onChange={handleChange}
            placeholder='Hier kannst du genau beschreiben, welche Daten falsch sind. Also z.B. Website / Öffnungszeiten.'
            required
          />
        </div>
        <CustomButton
          type='submit'
          sx={{
            width: '100%',
          }}
        >
          Senden
        </CustomButton>
        {status.message && (
          <Typography
            color={status?.error ? 'error' : 'primary'}
            style={{
              marginTop: '.3rem',
              marginBottom: '.3rem',
            }}
          >
            {status.message}
          </Typography>
        )}
      </form>
    </>
  );
};
