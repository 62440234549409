export const createRoutingUrl = (latitude, longitude) => {
  const defaultUrl = `https://www.openstreetmap.org/directions?engine=fossgis_osrm_bike&route=%3B&locale=de&from=&to=${latitude},${longitude}`;

  const location = sessionStorage.getItem('location');

  if (location) {
    // { lat: position.coords.latitude, lng: position.coords.longitude }
    const coords = JSON.parse(location);
    return `https://www.openstreetmap.org/directions?engine=fossgis_osrm_bike&route=%3B&locale=en&from=${coords.lat},${coords.lng}&to=${latitude},${longitude}`;
  }

  return defaultUrl;
};
